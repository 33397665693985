import queryString from 'query-string';

import { SearchFilterContentTypes } from '../../../Shared/enums';
import { ImageSelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import { categories } from '../../entities/ImageSearchFilterOptions';
import sharedSearchOptionsToQueryParams from './sharedSearchOptionsToQueryParams';
import { NewSearchLocation } from './types';

export default function imageSearchOptionsToLocation(
  options: Partial<ImageSelectedSearchFilterOptions>
): NewSearchLocation {
  const pathname = `/images/search${
    options.searchTerm ? '/' + encodeURIComponent(options.searchTerm) : ''
  }`;

  const queryParams = sharedSearchOptionsToQueryParams(options) as any;

  switch (options.contentType) {
    case SearchFilterContentTypes.Photos:
      queryParams['media-type'] = 'photos';
      break;
    case SearchFilterContentTypes.Snapshots:
      queryParams['media-type'] = 'snapshots';
      break;
    case SearchFilterContentTypes.Vectors:
      queryParams['media-type'] = 'vectors';
      break;
    case SearchFilterContentTypes.Illustrations:
      queryParams['media-type'] = 'illustrations';
      break;
  }

  if (options.categories) {
    queryParams.categories = categories.find(
      ({ contentType, value }) =>
        options.categories === value && options.contentType === contentType
    )?.urlId;
  }

  if (options.propertyReleased) {
    queryParams.hasPropertyReleased = options.propertyReleased;
  }

  if (options.talentReleased) {
    queryParams.hasTalentReleased = options.talentReleased;
  }

  if (options.hasTransparency) {
    queryParams.hasTransparency = options.hasTransparency;
  }

  if (options.orientation && options.orientation !== 'all') {
    queryParams.orientation = options.orientation;
  }

  if (options.usageRights && options.usageRights !== 'all') {
    queryParams.usage_rights = options.usageRights;
  }

  if (options.color) {
    queryParams.color = options.color;
  }

  const query = queryString.stringify(queryParams, {
    skipNull: true,
    skipEmptyString: true,
  });

  return { url: `${pathname}${query ? `?${query}` : ''}` };
}
